import React from 'react';
import {Router, Switch, Route} from 'dva/router';
import dynamic from 'dva/dynamic'

function RouterConfig({history, app}) {

    const routes = [
        {
            path: '/',
            models: () => [import('./routes/model'), import('./models/common')],
            component: () => import('./routes/index'),
        },
        {
            path: '/WorkCenter',
            models: () => [import('./routes/model'), import('./models/common')],
            component: () => import('./routes/index'),
        },
        {
            path: '/repair',
            models: () => [import('./routes/repair/model')],
            component: () => import('./routes/repair/index'),
        },
        {
            path: '/repair/equipment',
            models: () => [import('./routes/repair/equipment/model'), import('./models/common')],
            component: () => import('./routes/repair/equipment/index'),
        },
        {
            path: '/repair/part',
            models: () => [import('./routes/repair/part/model'), import('./models/common')],
            component: () => import('./routes/repair/part/index'),
        },
        {
            path: '/repair/part/edit',
            models: () => [import('./routes/repair/part/edit/model'), import('./models/common')],
            component: () => import('./routes/repair/part/edit/index'),
        },
        {
            path: '/repair/fault',
            models: () => [import('./routes/repair/fault/model'), import('./models/common')],
            component: () => import('./routes/repair/fault/index'),
        },
        {
            path: '/repair/create',
            models: () => [import('./routes/repair/create/model'), import('./models/common')],
            component: () => import('./routes/repair/create/index'),
        },
        {
            path: '/repair/all',
            models: () => [import('./routes/repair/all/model'), import('./models/common')],
            component: () => import('./routes/repair/all/index'),
        },
        {
            path: '/order',
            models: () => [import('./routes/order/model'), import('./models/common')],
            component: () => import('./routes/order/index'),
        },
        {
            path: '/order/syn',
            models: () => [import('./routes/order/syn/model'), import('./models/common')],
            component: () => import('./routes/order/syn/index'),
        },
        {
            path: '/order/part',
            models: () => [import('./routes/order/part/model'), import('./models/common')],
            component: () => import('./routes/order/part/index'),
        },
        {
            path: '/order/create',
            models: () => [import('./routes/order/create/model'), import('./models/common')],
            component: () => import('./routes/order/create/index'),
        },
        {
            path: '/order/edit',
            models: () => [import('./routes/order/edit/model'), import('./models/common')],
            component: () => import('./routes/order/edit/index'),
        },
        {
            path: '/order/department',
            models: () => [import('./routes/order/department/model'), import('./models/common')],
            component: () => import('./routes/order/department/index'),
        },
        {
            path: '/order/inventories',
            models: () => [import('./routes/order/inventories/model'), import('./models/common')],
            component: () => import('./routes/order/inventories/index'),
        },
        {
            path: '/order/userdept',
            models: () => [import('./routes/order/userdept/model'), import('./models/common')],
            component: () => import('./routes/order/userdept/index'),
        },
        {
            path: '/order/ebsUser',
            models: () => [import('./routes/order/ebsUser/model'), import('./models/common')],
            component: () => import('./routes/order/ebsUser/index'),
        },
        {
            path: '/maintain',
            models: () => [import('./routes/maintain/model'), import('./models/common')],
            component: () => import('./routes/maintain/index'),
        },
        {
            path: '/maintain/list',
            models: () => [import('./routes/maintain/list/model'), import('./models/common')],
            component: () => import('./routes/maintain/list/index'),
        },
        {
            path: '/maintain/content',
            models: () => [import('./routes/maintain/content/model'), import('./models/common')],
            component: () => import('./routes/maintain/content/index'),
        },
        {
            path: '/components/equipment',
            models: () => [import('./routes/components/equipment/model'), import('./models/common')],
            component: () => import('./routes/components/equipment/index'),
        },
        {
            path: '/pushwechat/allot/:id',
            models: () => [import('./routes/pushwechat/allot/model'), import('./models/common')],
            component: () => import('./routes/pushwechat/allot/index'),
        },
        {
            path: '/pushwechat/repair/part/:repair_id',
            models: () => [import('./routes/pushwechat/repair/part/model'), import('./models/common')],
            component: () => import('./routes/pushwechat/repair/part/index'),
        },
        {
            path: '/pushwechat/repair/part/edit/:repair_id',
            models: () => [import('./routes/pushwechat/repair/part/edit/model'), import('./models/common')],
            component: () => import('./routes/pushwechat/repair/part/edit/index'),
        },
        {
            path: '/pushwechat/repair/fault/:repair_id',
            models: () => [import('./routes/pushwechat/repair/fault/model'), import('./models/common')],
            component: () => import('./routes/pushwechat/repair/fault/index'),
        },
        {
            path: '/pushwechat/repair/create/:id',
            models: () => [import('./routes/pushwechat/repair/create/model'), import('./models/common')],
            component: () => import('./routes/pushwechat/repair/create/index'),
        },
        {
            path: '/pushwechat/maintain/:maintain_id',
            models: () => [import('./routes/pushwechat/maintain/model'), import('./models/common')],
            component: () => import('./routes/pushwechat/maintain/index'),
        },

        {
            path: '/spotcheck',
            models: () => [import('./routes/spotcheck/model'), import('./models/common')],
            component: () => import('./routes/spotcheck/index'),
        },
        {
            path: '/spotcheck/emslist',
            models: () => [import('./routes/spotcheck/emslist/model'), import('./models/common')],
            component: () => import('./routes/spotcheck/emslist/index'),
        },
        {
            path: '/spotcheck/emsdetails/:id',
            models: () => [import('./routes/spotcheck/emsdetails/model'), import('./models/common')],
            component: () => import('./routes/spotcheck/emsdetails/index'),
        },
        {
            path: '/spotcheck/handle',
            models: () => [import('./routes/spotcheck/handle/model'), import('./models/common')],
            component: () => import('./routes/spotcheck/handle/index'),
        },
        {
            path: '/spotcheck/inspec',
            models: () => [import('./routes/spotcheck/inspec/model'), import('./models/common')],
            component: () => import('./routes/spotcheck/inspec/index'),
        },
        {
            path: '/spotcheck/list',
            models: () => [import('./routes/spotcheck/list/model'), import('./models/common')],
            component: () => import('./routes/spotcheck/list/index'),
        },
        {
            path: '/spotcheck/details/:id',
            models: () => [import('./routes/spotcheck/details/model'), import('./models/common')],
            component: () => import('./routes/spotcheck/details/index'),
        },
        {
            path: '/scancode',
            models: () => [import('./routes/scancode/model'), import('./models/common')],
            component: () => import('./routes/scancode/index'),
        }
    ]

    return (
        <Router history={history}>
            <Switch>
                {
                    routes.map(({path, ...dynamics}, key) => (
                        <Route key={key}
                               exact
                               path={path}
                               component={dynamic({
                                   app,
                                   ...dynamics,
                               })}
                        />
                    ))
                }
            </Switch>
        </Router>
    )
}

export default RouterConfig;
